<script setup>
import { defineProps, computed } from "vue";

import { useStore } from "vuex";
import DictionaryForReport from "@/globals/DictionaryForReport";

import linesImage from "@/assets/report-images/lines.png";
import CandidateReportKPILegend from "@/components/DashboardComponents/ProfilesEvaluation/CandidateReport/CandidateReportKPILegend.vue";

const store = useStore();
const ProHigh = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][106];
});
const ProMedium = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][107];
});

function getRowClass(value) {
  if (value == ProHigh.value) return "high";
  else if (value == ProMedium.value) return "medium";
  else return "low";
}

let candidateOrgFitColor = computed(() => {
  return calculateFitKPIColor(props.reportData.mindsetFitIdealPercent);
});

let candidateOrgFitBgColor = computed(() => {
  return calculateFitKPIBackgroundColor(
    props.reportData.mindsetFitIdealPercent
  );
});

function calculateFitKPIColor(value) {
  if (value < 70) return "#F7E66B";
  if (value > 80) return "#5d8045";
  if (value >= 70 && value <= 80) return "#CADFB8";

  return "#F7E66B";
}

function calculateFitKPIBackgroundColor(value) {
  if (value < 70) return "#FBF3BB";
  if (value > 80) return "#C9E1B9";
  if (value >= 70 && value <= 80) return "#E7F0DE";

  return "#FBF3BB";
}

const props = defineProps({
  reportData: Object,
  chartColors: Array,
});

const ThisRepre = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][29];
});
const AdjustIdeal = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][108];
});

const kpiAdjust = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][110];
});
const footerText = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][3];
});
const ValOf = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][131];
});
const TTable = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][132];
});
const Val = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][53];
});

const Prob = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][55];
});
</script>

<template>
  <div id="appendix1Page1" class="page">
    <div class="page-body">
      <img :src="linesImage" class="page1-lines-logo" />

      <div class="main-container">
        <div class="title">
          {{ AdjustIdeal }}
          <strong>{{ reportData.name }}</strong> &
          <strong>{{ reportData.paramName }}</strong>
        </div>

        <p class="small-dark-green-field">
          {{ ThisRepre }}
        </p>

        <div class="title-s center">
          {{ kpiAdjust }} {{ reportData.name }} &
          <strong>{{ reportData.paramName }}</strong>
        </div>
        <div class="kpi center">
          <v-progress-circular
            :rotate="90"
            :size="120"
            :width="8"
            :model-value="reportData.mindsetFitIdealPercent"
            :color="candidateOrgFitColor"
            bg-color="transparent"
          >
            <v-avatar size="104" :color="candidateOrgFitBgColor">
              <div class="kpi-value-number">
                {{ reportData.mindsetFitIdealPercent }}
              </div>
            </v-avatar>
          </v-progress-circular>
        </div>
        <CandidateReportKPILegend />

        <div class="subtitle">
          {{ ValOf }}
          <strong>{{ reportData.paramName }}</strong
          >:
        </div>
        <p class="small-dark-green-field" v-html="TTable"></p>
        <p class="small-dark-green-field">
          {{ reportData.paramName }}
        </p>

        <v-table v-bind="props">
          <tr>
            <td class="dark-green">{{ Val }}</td>

            <td class="dark-green">{{ Prob }}</td>
          </tr>
          <tr v-for="(value, key) in reportData.ParamBehaviours" :key="key">
            <td class="light-green-dt">{{ value.value }}</td>

            <td class="regular-cell" :class="getRowClass(value.probability)">
              {{ value.probability }}
            </td>
          </tr>
        </v-table>
      </div>
    </div>
    <div class="page-footer">
      {{ footerText }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
.chart {
  width: 260px;
  height: 260px;
  margin-left: auto;
  margin-right: auto;
}
.chart2 {
  width: 630px;
  height: 630px;
  margin-left: auto;
  margin-right: auto;
}

.page1-lines-logo {
  display: block;
  width: 58px;
  position: absolute;
  margin-top: 380px;
  right: 0px;
  transform: rotateY(180deg);
}

table {
  td {
    border: none !important;
  }
}
</style>
