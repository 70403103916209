export default {
    defaultParametersId: [
        91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111
    ],
    colorCulturaParametrizada: '#02c071', // Verde claro (MindsetFit) 
    colorCulturaVividaDiagnostico: '#00c3c0', // Azul Claro (Cultura vivida internamente)
    colorOfDesireValues: '#b39b3e', // Amarillo tipo Ocre (Cultura deseada internamente)
    colorOfCandidateValues: '#34495e', // Azul oscuro (Valores del candidato)
    
    list6AllZero: Array(6).fill(0),
    list36AllZero: Array(36).fill(0),
}