import Values from "./Values"

export default {
    transformaDataList(list) {
        if (!list) return Values.list36AllZero

        const allElementsEqual = list.every(e => e === list[0]);
        const AllString = list.every(e => typeof e === 'string')

        if (AllString) list = list.map(e => Number(e))
        if (allElementsEqual) list = list.map(() => 0)

        return list
    },
}