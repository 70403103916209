<script setup>
import dotsImage from "@/assets/report-images/dots.png";
import minzerLogoImage from "@/assets/report-images/minzer-logo.png";
import linesImage from "@/assets/report-images/lines.png";
import { useStore } from "vuex";
import DictionaryForReport from "@/globals/DictionaryForReport";
import { computed } from "vue";

const store = useStore();

const footerText = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][3];
});
</script>

<template>
  <div id="backPage" class="page">
    <div class="page-body">
      <img :src="dotsImage" class="page1-dots" />
      <img :src="minzerLogoImage" class="page1-minzer-logo" />
      <img :src="linesImage" class="page1-lines-logo" />
      <div class="cover-green-rect"></div>
      <div class="cover-dark-green-rect"></div>
    </div>
    <div class="page-footer">
      {{ footerText }}
    </div>
  </div>
</template>

<style scoped>
.page1-dots {
  display: block;
  width: 183px;
  position: absolute;
  margin-top: 166px;
  left: 88px;
}

.page1-minzer-logo {
  display: block;
  width: 289px;
  position: absolute;
  margin-top: 296px;
  right: 205px;
}

.page1-lines-logo {
  display: block;
  width: 58px;
  position: absolute;
  margin-top: 514px;
  left: 129px;
}

.cover-green-rect {
  width: 40px;
  height: 40px;
  position: absolute;
  right: 65px;
  margin-top: 860px;
  background-color: #2eb6b9;
}

.cover-dark-green-rect {
  width: 24px;
  height: 24px;
  position: absolute;
  right: 54px;
  margin-top: 818px;
  background-color: #33495f;
}
</style>
