<template>
  <div class="widget-container">
    <div class="widget-header">
      <h4 class="widget-title text-uppercase">
        Mentalidades predominantes en la persona
      </h4>
    </div>

    <v-tooltip text="Ranking de predominancia de culturas en la persona" bottom>
      <template v-slot:activator="{ on }">
        <v-row
          :class="smAndDown ? 'd-flex justify-end' : 'd-flex justify-center'"
          v-on="on"
        >
          <v-col cols="12">
            <div class="scrollable-container">
              <div class="table-container">
                <div
                  class="table-row"
                  v-for="(item, index) in cultureProfileData.predominance"
                  :key="item.title"
                >
                  <div class="circle-cell">
                    <v-icon :color="table_colors[index]" size="10"
                      >mdi-circle</v-icon
                    >
                  </div>
                  <div class="title-cell">{{ item.title }}</div>
                  <div class="number-cell">Top {{ index + 1 }}</div>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </template>
    </v-tooltip>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { useDisplay } from "vuetify";
import { computed } from "vue";

export default {
  name: "CandidatePredominanceTable",
  data: () => ({
    table_colors: [
      "#24AC0E",
      "#4CD737",
      "#01C3C0",
      "#CDC615",
      "#CD9913",
      "#CD4114",
    ],

    table_legend: "",
  }),

  methods: {
    set_texts() {
      let t = sessionStorage.getItem("texts");
      let texts = JSON.parse(t);
      this.table_legend = texts[97];
    },
  },

  created() {
    this.set_texts();
  },

  computed: {
    ...mapState(["cultureProfileData"]),
  },

  setup() {
    // Destructure only the keys we want to use
    const { xlAndUp } = useDisplay();
    const { smAndDown } = useDisplay();

    const { name } = useDisplay();

    const signs_height = computed(() => {
      // name is reactive and
      // must use .value
      switch (name.value) {
        case "xs":
          return "50px";
        case "sm":
          return "50px";
        case "md":
          return (
            (15 *
              ((87 * ((45 * ((92 * window.innerHeight) / 100)) / 100)) / 100)) /
            100
          );
        case "lg":
          return (
            (15 *
              ((87 * ((45 * ((92 * window.innerHeight) / 100)) / 100)) / 100)) /
            100
          );
        case "xl":
          return (
            (15 *
              ((90 * ((45 * ((92 * window.innerHeight) / 100)) / 100)) / 100)) /
            100
          );
        case "xxl":
          return (
            (15 *
              ((90 * ((45 * ((92 * window.innerHeight) / 100)) / 100)) / 100)) /
            100
          );
      }

      return undefined;
    });

    const text_height = computed(() => {
      // name is reactive and
      // must use .value
      switch (name.value) {
        case "xs":
          return "130px";
        case "sm":
          return "130px";
        case "md":
          return (
            (50 *
              ((87 * ((45 * ((92 * window.innerHeight) / 100)) / 100)) / 100)) /
            100
          );
        case "lg":
          return (
            (50 *
              ((87 * ((45 * ((92 * window.innerHeight) / 100)) / 100)) / 100)) /
            100
          );
        case "xl":
          return (
            (50 *
              ((90 * ((45 * ((92 * window.innerHeight) / 100)) / 100)) / 100)) /
            100
          );
        case "xxl":
          return (
            (50 *
              ((90 * ((45 * ((92 * window.innerHeight) / 100)) / 100)) / 100)) /
            100
          );
      }

      return undefined;
    });

    return {
      xlAndUp,
      smAndDown,
      signs_height,
      text_height,
    };
  },
};
</script>

<style>
.widget-container {
  width: 100%;
  padding: 4%;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #efefef;
  height: fit-content;
}

.widget-header {
  margin-bottom: 24px;
}

.widget-title {
  color: #333;
  margin-bottom: 1px;
}

.subtitle33 {
  color: #585858;
  font-size: 13px;
}

.table-container {
  width: 100%;
  overflow-x: auto; /* Añadido para desplazamiento horizontal */
}

.table-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0;
  border-bottom: 1px solid #e0e0e0;
}

.circle-cell {
  margin-right: 18px;
}

.title-cell {
  flex-grow: 1;
  text-align: left;
  font-size: 19px; /* Tamaño para pantalla grande */
  color: #333;
}

.number-cell {
  text-align: right;
  font-size: 14px; /* Tamaño para pantalla grande */
  color: #747474;
}

@media (max-width: 1500px) {
  .title-cell {
    font-size: 14px; /* Tamaño más pequeño para la pantalla del portátil */
  }

  .number-cell {
    font-size: 12px; /* Tamaño más pequeño para la pantalla del portátil */
  }

  .circle-cell v-icon {
    font-size: 13px; /* Tamaño más pequeño para la pantalla del portátil */
  }

  .widget-title,
  .subtitle33 {
    font-size: 13px; /* Tamaño más pequeño para la pantalla del portátil */
  }
  .table-container {
    margin-top: -13px;
  }
}

@media (max-width: 992px) {
  /* Ajustes para dispositivos aún más pequeños, como tabletas */

  .title-cell {
    font-size: 13px; /* Tamaño más pequeño para pantallas de tabletas */
  }

  .number-cell {
    font-size: 9px; /* Tamaño más pequeño para pantallas de tabletas */
  }

  /* Podrías añadir aquí más ajustes si es necesario */
}
</style>
