<template>
  <v-form v-model="form">
    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model="full_name"
          required
          density="compact"
          class="text-fields text-grey-darken-2"
          type="text"
          bg-color="#BDF0EE"
          :placeholder="full_name_placeholder"
          variant="outlined"
          single-line
          :rules="fullnameRules"
          hide-details
          @keydown.enter="ckeckForm"
          autocomplete="off"
        >
          <template v-slot:prepend-inner>
            <v-icon color="#00C3C0">mdi mdi-account</v-icon>
          </template>
        </v-text-field>

        <v-text-field
          v-model="email"
          required
          density="compact"
          class="text-fields text-grey-darken-2 mt-1"
          type="text"
          bg-color="#BDF0EE"
          :placeholder="email_placeholder"
          variant="outlined"
          single-line
          :rules="emailRules"
          hide-details
          @keydown.enter="ckeckForm"
          autocomplete="new-email"
        >
          <template v-slot:prepend-inner>
            <v-icon color="#00C3C0">mdi mdi-email</v-icon>
          </template>
        </v-text-field>

        <v-text-field
          v-model="company_name"
          required
          density="compact"
          class="text-fields text-grey-darken-2 mt-1"
          type="text"
          bg-color="#BDF0EE"
          :placeholder="company_name_placeholder"
          variant="outlined"
          single-line
          hide-details
          :rules="companynameRules"
          @keydown.enter="ckeckForm"
          autocomplete="off"
        >
          <template v-slot:prepend-inner>
            <v-icon color="#00C3C0">mdi mdi-domain</v-icon>
          </template>
        </v-text-field>

        <v-text-field
          v-model="password"
          required
          density="compact"
          class="text-fields text-grey-darken-2 mt-1"
          :append-inner-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
          :type="show1 ? 'text' : 'password'"
          @click:append-inner="show1 = !show1"
          bg-color="#BDF0EE"
          :placeholder="password_placeholder"
          variant="outlined"
          single-line
          hide-details
          :rules="passwordRules"
          @keydown.enter="ckeckForm"
          autocomplete="new-password"
        >
          <template v-slot:prepend-inner>
            <v-icon color="#00C3C0">mdi mdi-lock</v-icon>
          </template>
        </v-text-field>

        <v-text-field
          v-model="password_repeat"
          required
          density="compact"
          class="text-fields text-grey-darken-2 mt-1"
          :append-inner-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
          :type="show2 ? 'text' : 'password'"
          @click:append-inner="show2 = !show2"
          bg-color="#BDF0EE"
          :placeholder="password_repeat_placeholder"
          variant="outlined"
          single-line
          hide-details
          :rules="password_repeatRules"
          @keydown.enter="ckeckForm"
          autocomplete="new-password"
        >
          <template v-slot:prepend-inner>
            <v-icon color="#00C3C0">mdi mdi-lock</v-icon>
          </template>
        </v-text-field>
        <p class="mt-2" style="color: #585858; font-size: 10px">
          {{ password_requirements }}
        </p>
      </v-col>
    </v-row>
    <v-row class="py-0">
      <v-col cols="12" class="py-0">
        <p class="text-justify" style="color: #585858; font-size: 12px">
          {{ terms_and_conditions }}
          <a 
            href="https://www.minzer.io/en-US/terms" 
            target="_blank" 
            style="text-decoration: none; color: #00c3c0;"
          >
            {{ here }}
          </a>
        </p>
      </v-col>
    </v-row>
    <v-row class="py-0">
      <v-col cols="12" class="py-0">
        <v-btn
          block
          rounded="lg"
          :disabled="!form"
          density="comfortable"
          size="large"
          class="font-weight-bold signin_button mb-3 mt-3"
          @click="ckeckForm"
        >
          {{ signin_btn_label }}
          <!--Email Validation Dialog-->
          <RegistrationEmailValidationDialog :user_email="email" />
        </v-btn>

        <!--   <v-btn
          block
          rounded="lg"
          density="comfortable"
          size="large"
          :class="mdAndUp ? 'font-weight-bold mb-5' : 'font-weight-bold mb-10'"
          @click="goto_linkedin"
        >
          {{ linkedin_btn_label }}
          <template v-slot:append>
            <v-icon color="light-blue-darken-4" size="30"
              >mdi mdi-linkedin</v-icon
            >
          </template>
        </v-btn> -->
      </v-col>
    </v-row>
  </v-form>

  <v-snackbar
    v-model="snackbar"
    :timeout="2000"
    variant="elevated"
    class="mb-5"
    location="bottom right"
    color="error"
    width="auto"
    transition="scroll-x-reverse-transition"
  >
    <h3 class="text-white">
      <v-icon color="white" class="mr-1" size="30">mdi mdi-check-circle</v-icon>
      {{ snack_message }}
    </h3>
  </v-snackbar>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import { ROUTES } from "/src/router/names";
import { useDisplay } from "vuetify";

//API Services
import LoginService from "@/services/LoginService";

import RegistrationEmailValidationDialog from "./RegistrationEmailValidationDialog.vue";

export default {
  name: "SigninDataForm",
  components: {
    RegistrationEmailValidationDialog,
  },

  data: () => ({
    //Form Data
    form: false,

    //Show Password
    show1: false,
    show2: false,

    //Snackbar Notification
    snackbar: false,
    snack_message: "",

    //Text Field Model
    full_name: "",
    email: "",
    company_name: "",
    password: "",
    password_repeat: "",

    //Signin Form TextField Placeholder
    full_name_placeholder: "Nombre Completo",
    email_placeholder: "E-mail Corporativo",
    company_name_placeholder: "Nombre de la empresa",
    password_placeholder: "Contraseña",
    password_repeat_placeholder: "Confirmar Contraseña",

    password_requirements: "",

    //Terms
    terms_and_conditions: "",
    here: "",

    //Signin Form Actions Buttons
    signin_btn_label: "REGISTRAR",
    linkedin_btn_label: "O CONTINUAR CON LINKEDIN",

    //Text Field Rules
    fullnameRules: [(v) => !!v || "Campo requerido"],
    companynameRules: [(v) => !!v || "Campo requerido"],
    passwordRules: [
      (v) => !!v || "Contraseña requerida",
      (v) =>
        (v && v.length <= 20) ||
        "La contraseña no debe tener más de 20 caracteres",
    ],
    password_repeatRules: [
      (v) => !!v || "Contraseña requerida",
      (v) =>
        (v && v.length <= 20) ||
        "La contraseña no debe tener más de 20 caracteres",
    ],
    emailRules: [(v) => !!v || "Usuario requerido"],

    ROUTES: ROUTES,
  }),

  computed: {
    ...mapState(["signin_form"]),
  },

  created() {
    this.set_texts();
  },

  methods: {
    ...mapMutations([
      "setTrue_registration_email_validation_dialog",
      "setRegistered_user",
    ]),

    set_texts() {
      let t = sessionStorage.getItem("texts");
      let texts = JSON.parse(t);

      this.full_name_placeholder = texts[6];
      this.email_placeholder = texts[7];
      this.company_name_placeholder = texts[8];
      this.password_placeholder = texts[9];
      this.password_repeat_placeholder = texts[10];

      this.password_requirements = texts[11];

      this.terms_and_conditions = texts[95];
      this.here = texts[96];

      this.signin_btn_label = texts[2];
      this.linkedin_btn_label = texts[13];
    },

    goto_linkedin() {},

    validEmail(email) {
      let re =
        /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },

    validPassword(password) {
      let re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d$@$!%*?&_.]{6,20}/;
      return re.test(password);
    },

    ckeckForm() {
      if (!this.validEmail(this.email)) {
        this.snack_message = "El email debe ser válido";
        this.snackbar = true;
        this.email = "";
      } else {
        if (!this.validPassword(this.password)) {
          this.snack_message =
            "Contraseña muy débil. Debe cumplir con los requisitos";
          this.snackbar = true;
          this.password = "";
          this.password_repeat = "";
        } else {
          if (this.password != this.password_repeat) {
            this.snack_message = "Confirmación de la contraseña incorrecta";
            this.snackbar = true;
            this.password = "";
            this.password_repeat = "";
          } else {
            this.registerUser();
          }
        }
      }
    },

    async registerUser() {
      let formData = new FormData();
      formData.append("email", this.email);
      formData.append("company_name", this.company_name);
      formData.append("full_name", this.full_name);
      formData.append("password", this.password);

      try {
        const response = await LoginService.registerUser(formData);
        let data = response.data;

        if (data.code === 500) {
          this.snack_message = "Este usuario ya existe";
          this.snackbar = true;
          this.email = "";
          this.company_name = "";
          this.full_name = "";
          this.password = "";
          this.password_repeat = "";
        }

        if (data.status === "success") {
          this.setRegistered_user(data.user);
          this.setTrue_registration_email_validation_dialog();
        }
      } catch (error) {
        console.log(error);
      }
    },
  },

  setup() {
    // Destructure only the keys we want to use
    const { mdAndUp } = useDisplay();

    return { mdAndUp };
  },
};
</script>

<style scoped>
.text-fields {
  font-style: italic;
  font-weight: bold;
}

.signin_button {
  background-color: #34495e;
  color: white;
}

.text-fields :-webkit-autofill,
.text-fields :-webkit-autofill:hover,
.text-fields :-webkit-autofill:focus,
.text-fields :-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #585858 !important;
}

.text-fields .v-text-field--outlined >>> fieldset {
  border-color: rgba(192, 0, 250, 0.986);
}
</style>
