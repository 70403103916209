<template>
  <!--Go to Candidate Detailed View -->
  <v-window v-model="detailed_view_step">
    <v-window-item :value="0">
      <CandidatesGeneralView />
    </v-window-item>

    <v-window-item :value="1">
      <CandidateDetailedView />
    </v-window-item>

    <v-window-item :value="2">
      <BoxParameterizationView />
    </v-window-item>

    <v-window-item :value="3">
      <ParameterizationView />
    </v-window-item>

    <v-window-item :value="4">
      <CandidatesGeneralIdealFitView />
    </v-window-item>

    <v-window-item :value="5">
      <ModifyParametrization />
    </v-window-item>

    <v-window-item :value="6">
      <DinamicParameterizationView />
    </v-window-item>
  </v-window>
</template>

<script>
import { defineComponent } from "vue";

import { mapMutations, mapState } from "vuex";

//service

import LoginService from "@/services/LoginService";
import BoxParameterizationView from "@/views/BoxParameterizationView.vue";
import CandidateDetailedView from "@/views/CandidateDetailedView.vue";
import CandidatesGeneralIdealFitView from "@/views/CandidatesGeneralIdealFitView.vue";
import ModifyParametrization from "@/views/ModifyParametrization.vue";
import ParameterizationView from "@/views/ParameterizationView.vue";
import DinamicParameterizationView from "@/views/DinamicParameterizationView.vue";

// Components
import CandidatesGeneralView from "@/views/CandidatesGeneralView.vue";

export default defineComponent({
  name: "ProfilesEvaluationView",

  components: {
    CandidatesGeneralView,
    CandidateDetailedView,
    BoxParameterizationView,
    ParameterizationView,
    DinamicParameterizationView,
    CandidatesGeneralIdealFitView,
    ModifyParametrization,
  },

  computed: {
    ...mapState(["detailed_view_step"]),
  },

  methods: {
    ...mapMutations(["setUser_tokens", "getUserFromSessionStorage"]),

    async getUserToken() {
      try {
        const response = await LoginService.getUserToken(this.user_info.email);
        let data = response.data;

        this.setUser_tokens(data);
      } catch (error) {
        console.log(error);
      }
    },
  },

  async created() {
    this.getUserFromSessionStorage();
  },
});
</script>
