<template>
  <!--Center sheet-->
    <v-sheet
      variant="outlined"
      :class="mdAndUp ? 'mx-auto sheet' : 'mx-3 mt-5 sheet'"
      :height="card_height"
      :width="sheet_width"
    >
      <v-row class="pa-0" dense no-gutters>
        <!--Picture Column-->
        <v-col cols="12" sm="12" md="12" lg="6" xl="7" class="pa-0">
          <v-img
            src="@/assets/CFAMapplication.png"
            class="d-flex align-center text-center rounded"
            :height="card_height"
            cover
          >
            <v-avatar color="transparent" size="75%" rounded="0">
              <v-img src="@/assets/MinzerWhiteLogo.png"></v-img>
            </v-avatar>
          </v-img>
        </v-col>

        <!--Forms Column-->
        <v-col cols="12" sm="12" md="12" lg="6" xl="5" :class="mdAndUp ? '' : 'mt-10'">
          <v-sheet
            class="mx-auto d-flex align-center "
            color="transparent"
            :height="card_height"
            :width="mdAndUp ? '75%' : '90%'"
          >
            <v-sheet class="w-100 bg-transparent">      
              <v-row class="">
                <v-col cols="12">
                  <!--Edit Profile Form-->
                  <RecoverPasswordForm />
                </v-col>
              </v-row>
            </v-sheet>
          </v-sheet>
        </v-col>
      </v-row>
    </v-sheet>
</template>
    
  <script>
import { defineComponent } from "vue";
import { useDisplay } from "vuetify";
import { computed } from "vue";

// Components
import RecoverPasswordForm from "@/components/RecoverPasswordComponents/RecoverPasswordForm.vue";

export default defineComponent({
  name: "RecoverPasswordSheet",

  data: () => ({}),

  components: {
    RecoverPasswordForm,
  },

  setup() {
    // Destructure only the keys we want to use
    const { mdAndUp } = useDisplay();
    const { xsAndUp } = useDisplay();

    const { name } = useDisplay();

    const sheet_height = computed(() => {
      // name is reactive and
      // must use .value
      switch (name.value) {
        case "xs":
          return 'auto';
        case "sm":
          return 'auto';
        case "md":
          return 200;
        case "lg":
          return 610;
        case "xl":
          return 700;
        case "xxl":
          return 350;
      }

      return undefined;
    });

    const sheet_width = computed(() => {
      // name is reactive and
      // must use .value
      switch (name.value) {
        case "xs":
          return 'auto';
        case "sm":
          return 'auto';
        case "md":
          return 200;
        case "lg":
          return 1200;
        case "xl":
          return 1500;
        case "xxl":
          return 350;
      }

      return undefined;
    });

    const card_height = computed(() => {
      // name is reactive and
      // must use .value
      switch (name.value) {
        case "xs":
          return "auto";
        case "sm":
          return "auto";
        case "md":
          return (92 * ((88 * window.innerHeight) / 100)) / 100;
        case "lg":
          return (95 * ((88 * window.innerHeight) / 100)) / 100;
        case "xl":
          return (95 * ((88 * window.innerHeight) / 100)) / 100;
        case "xxl":
          return (95 * ((88 * window.innerHeight) / 100)) / 100;
      }

      return undefined;
    });

    // eslint-disable-next-line vue/no-dupe-keys
    return { sheet_height, card_height, sheet_width, mdAndUp, xsAndUp };
  },
});
</script>
  
  <style scoped>
.btn_selected {
  color: white !important;
  background-color: #34495e !important;
}

.btn_toggles {
  background-color: #d9dde0;
  color: #34495e;
}

.sheet {
  border: none !important;
  background: transparent !important;
}
</style>