<template>
  <div :class="mdAndUp ? 'd-flex flex-row mt-1' : ''">
    <v-sheet
      color="transparent"
      :height="bottom_sheet_height"
      :width="left_sheet_width"
    >
      <v-row dense class="">
        <!--Candidate Profile Culture Chart-->
        <v-col cols="12" md="6" class="pa-0 cardwhitePV">
          <CandidateCultureProfileChart />
        </v-col>

        <!--Candidate Profile Culture Chart Data Set Table-->
        <v-col cols="12" md="5">
          <CandidatePredominanceTable />
        </v-col>
      </v-row>

      <!--Culture Apects Descriptions-->
      <v-col cols="12">
        <CultureAspectsDescription :arch_titles_PRED="arch_titles_PRED" />
      </v-col>

      <v-col cols="12" md="12">
        <div class="text-justify footer">
          <p>
            Queda prohibida la reproducción total o parcial del material
            protegido por estos derechos de propiedad intelectual (NÚMERO DE
            ASIENTO REGISTRAL 04 / 2022 / 3374), o su uso en cualquier forma, o
            por cualquier medio, ya sea electrónico o mecánico, incluyendo
            fotocopiado, grabación, transmisión o cualquier sistema de
            almacenamiento y recuperación de información, sin el permiso por
            escrito de Cultural Fit Solutions S.L.
          </p>
        </div>
      </v-col>
    </v-sheet>
    <v-sheet
      :color="mdAndUp ? 'transparent' : 'transparent'"
      height="bottom_sheet_height"
      :width="right_sheet_width"
    >
      <v-row dense>
        <v-col cols="12">
          <FitAreasSection v-bind:candidateMindsetFit="candidateMindsetFit"/>
        </v-col>
      </v-row>
    </v-sheet>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { computed } from "vue";
import { useDisplay } from "vuetify";

// Components
import CandidateCultureProfileChart from "@/components/DashboardComponents/ProfilesEvaluation/CandidateCultureProfileChart.vue";
import CandidatePredominanceTable from "@/components/DashboardComponents/ProfilesEvaluation/CandidatePredominanceTable.vue";
import CultureAspectsDescription from "@/components/DashboardComponents/ProfilesEvaluation/CultureAspectsDescription.vue";

import FitAreasSection from "@/components/DashboardComponents/ProfilesEvaluation/FitAreasSection.vue";

import { mapState } from "vuex";

export default defineComponent({
  name: "CandidateCultureProfileView",
  props: {},
  components: {
    CandidateCultureProfileChart,
    CandidatePredominanceTable,
    CultureAspectsDescription,

    FitAreasSection,
  },

  data() {
    return {};
  },

  computed: {
    ...mapState([
      "candidateMindsetFit",
    ]),
  },

  methods: {},

  created() {},

  setup() {
    // Destructure only the keys we want to use
    const { mdAndUp } = useDisplay();
    const { xsAndUp } = useDisplay();

    const { name } = useDisplay();

    const principles_card_height = computed(() => {
      // name is reactive and
      // must use .value
      switch (name.value) {
        case "xs":
          return "auto";
        case "sm":
          return "auto";
        case "md":
          return (90 * ((92 * window.innerHeight) / 100)) / 100;
        case "lg":
          return (90 * ((92 * window.innerHeight) / 100)) / 100;
        case "xl":
          return (92 * ((92 * window.innerHeight) / 100)) / 100;
        case "xxl":
          return (92 * ((92 * window.innerHeight) / 100)) / 100;
      }

      return undefined;
    });

    const charts_sheet_height = computed(() => {
      // name is reactive and
      // must use .value
      switch (name.value) {
        case "xs":
          return "auto";
        case "sm":
          return "auto";
        case "md":
          return (87 * ((45 * ((92 * window.innerHeight) / 100)) / 100)) / 100;
        case "lg":
          return (87 * ((50 * ((92 * window.innerHeight) / 100)) / 100)) / 100;
        case "xl":
          return (90 * ((45 * ((92 * window.innerHeight) / 100)) / 100)) / 100;
        case "xxl":
          return (90 * ((45 * ((92 * window.innerHeight) / 100)) / 100)) / 100;
      }

      return undefined;
    });

    const left_sheet_width = computed(() => {
      // name is reactive and
      // must use .value
      switch (name.value) {
        case "xs":
          return "auto";
        case "sm":
          return "auto";
        case "md":
          return (65 * window.innerWidth) / 100;
        case "lg":
          return (65 * window.innerWidth) / 100;
        case "xl":
          return (65 * window.innerWidth) / 100;
        case "xxl":
          return (65 * window.innerWidth) / 100;
      }

      return undefined;
    });

    const right_sheet_width = computed(() => {
      // name is reactive and
      // must use .value
      switch (name.value) {
        case "xs":
          return "auto";
        case "sm":
          return "auto";
        case "md":
          return (35 * window.innerWidth) / 100;
        case "lg":
          return (35 * window.innerWidth) / 100;
        case "xl":
          return (35 * window.innerWidth) / 100;
        case "xxl":
          return (35 * window.innerWidth) / 100;
      }

      return undefined;
    });

    const left_top__sheet_height = computed(() => {
      // name is reactive and
      // must use .value
      switch (name.value) {
        case "xs":
          return "auto";
        case "sm":
          return "auto";
        case "md":
          return (45 * ((80 * ((92 * window.innerHeight) / 100)) / 100)) / 100;
        case "lg":
          return (45 * ((80 * ((92 * window.innerHeight) / 100)) / 100)) / 100;
        case "xl":
          return (40 * ((85 * ((92 * window.innerHeight) / 100)) / 100)) / 100;
        case "xxl":
          return (50 * ((85 * ((92 * window.innerHeight) / 100)) / 100)) / 100;
      }

      return undefined;
    });

    const left_bottom_sheet_height = computed(() => {
      // name is reactive and
      // must use .value
      switch (name.value) {
        case "xs":
          return "auto";
        case "sm":
          return "auto";
        case "md":
          return (55 * ((80 * ((92 * window.innerHeight) / 100)) / 100)) / 100;
        case "lg":
          return (55 * ((80 * ((92 * window.innerHeight) / 100)) / 100)) / 100;
        case "xl":
          return (50 * ((85 * ((92 * window.innerHeight) / 100)) / 100)) / 100;
        case "xxl":
          return (55 * ((85 * ((92 * window.innerHeight) / 100)) / 100)) / 100;
      }

      return undefined;
    });

    const bottom_sheet_height = computed(() => {
      // name is reactive and
      // must use .value
      switch (name.value) {
        case "xs":
          return "auto";
        case "sm":
          return "auto";
        case "md":
          return (80 * ((92 * window.innerHeight) / 100)) / 100;
        case "lg":
          return (85 * ((92 * window.innerHeight) / 100)) / 100;
        case "xl":
          return (85 * ((92 * window.innerHeight) / 100)) / 100;
        case "xxl":
          return (85 * ((92 * window.innerHeight) / 100)) / 100;
      }

      return undefined;
    });

    // eslint-disable-next-line vue/no-dupe-keys
    return {
      bottom_sheet_height,
      right_sheet_width,
      left_sheet_width,
      left_bottom_sheet_height,
      left_top__sheet_height,
      principles_card_height,
      charts_sheet_height,
      mdAndUp,
      xsAndUp,
    };
  },
});
</script>
<style>
.cardwhitePV {
  background-color: #ffffff;
  border-radius: 10px;
  border: 1px solid #efefef;
  margin-left: 4%;
  max-width: 93.5%;
  max-height: 68%;
}
.footer {
  font-size: 58%;
  font-style: italic;
  color: #585858;
}
</style>
