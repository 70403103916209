<template>
  <v-row dense :class="mdAndUp ? '' : 'mt-4'">
    <v-col
      cols="7"
      :class="
        mdAndUp ? 'd-flex align-center justify-end' : 'd-flex justify-center'
      "
    >
      <v-tooltip :text="tooltip" location="top">
        <template v-slot:activator="{ props }">
          <v-progress-circular
            v-bind="props"
            class="mb-1"
            :rotate="360"
            :size="progress_circular_size"
            :width="14"
            :model-value="teamAndCompanyAdjustment.KPI"
            :color="color"
            bg-color="transparent"
          >
            <v-avatar :color="background_color" :size="avatar_size">
              <h1 style="color: #34495e">{{ teamAndCompanyAdjustment.KPI }}</h1>
            </v-avatar>
          </v-progress-circular>
        </template>
      </v-tooltip>
    </v-col>
    <v-col cols="5" class="d-flex align-center justify-center">
      <v-card
        elevation="0"
        color="transparent"
        class="rounded-lg legend"
        :width="md ? '90%' : '75%'"
      >
        <v-container fluid class="pa-3">
          <v-row dense class="d-flex justify-center pa-0">
            <v-col cols="12" class="text-center pa-0">
              <h6>ALTO</h6>
            </v-col>
          </v-row>
          <v-row dense class="d-flex justify-center pa-0">
            <V-col cols="5" class="d-flex align-center justify-end pa-0">
              <div
                class="rounded-pill pl-6 pt-2 w-25"
                style="background-color: #8abe66"
              ></div>
            </V-col>
            <V-col cols="7" class="d-flex align-center text-center pa-0">
              <p class="ml-1 legend_text">Más de 80</p>
            </V-col>
          </v-row>
          <v-row dense class="d-flex justify-center pa-0">
            <v-col cols="12" class="text-center pa-0">
              <h6 class="mt-3">ESTÁNDAR</h6>
            </v-col>
          </v-row>
          <v-row dense class="d-flex justify-center pa-0">
            <V-col cols="5" class="d-flex align-center justify-end pa-0">
              <div
                class="rounded-pill pl-6 pt-2 w-25 pa-0"
                style="background-color: #cadfb8"
              ></div>
            </V-col>
            <V-col cols="7" class="d-flex align-center justify-center pa-0">
              <p class="ml-1 legend_text">70-80</p>
            </V-col>
          </v-row>
          <v-row dense class="d-flex justify-center pa-0">
            <v-col cols="12" class="text-center pa-0">
              <h6 class="mt-3">BAJO</h6>
            </v-col>
          </v-row>
          <v-row dense class="d-flex justify-center pa-0">
            <V-col cols="5" class="d-flex align-center justify-end pa-0">
              <div
                class="rounded-pill pl-6 pt-2 w-25"
                style="background-color: #f7e66b"
              ></div>
            </V-col>
            <V-col cols="7" class="d-flex align-center text-center pa-0">
              <p class="ml-1 legend_text">Menos de 70</p>
            </V-col>
          </v-row>
        </v-container>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
import { useDisplay } from "vuetify";
import { computed } from "vue";

export default {
  name: "EngagementDegree",

  data: () => ({
    tooltip: "",
  }),

  computed: {
    ...mapState(["teamAndCompanyAdjustment"]),

    color() {
      return this.color_degree();
    },

    background_color() {
      return this.background_color_degree();
    },
  },

  methods: {
    color_degree() {
      if (this.teamAndCompanyAdjustment.KPI <= 70) {
        return "#F7E66B";
      }
      if (this.teamAndCompanyAdjustment.KPI >= 80) {
        return "#8ABE66";
      }
      if (this.teamAndCompanyAdjustment.KPI > 70 && this.teamAndCompanyAdjustment.KPI < 80) {
        return "#CADFB8";
      }
    },

    background_color_degree() {
      if (this.teamAndCompanyAdjustment.KPI <= 70) {
        return "#FBF3BB";
      }
      if (this.teamAndCompanyAdjustment.KPI >= 80) {
        return "#C9E1B9";
      }

      if (this.teamAndCompanyAdjustment.KPI > 70 && this.teamAndCompanyAdjustment.KPI < 80) {
        return "#E7F0DE";
      }
    },

    set_texts() {
      let t = sessionStorage.getItem("texts");
      let texts = JSON.parse(t);
      this.tooltip = texts[54];
    },
  },

  created() {
    this.set_texts();
  },

  setup() {
    // Destructure only the keys we want to use
    const { mdAndUp } = useDisplay();
    const { md } = useDisplay();
    const { xsAndUp } = useDisplay();

    const { name } = useDisplay();

    const progress_circular_size = computed(() => {
      // name is reactive and
      // must use .value
      switch (name.value) {
        case "xs":
          return 180;
        case "sm":
          return 180;
        case "md":
          return (60 * ((45 * ((92 * window.innerHeight) / 100)) / 100)) / 100;
        case "lg":
          return (50 * ((45 * ((92 * window.innerHeight) / 100)) / 100)) / 100;
        case "xl":
          return (50 * ((45 * ((92 * window.innerHeight) / 100)) / 100)) / 100;
        case "xxl":
          return (65 * ((45 * ((92 * window.innerHeight) / 100)) / 100)) / 100;
      }

      return undefined;
    });

    const avatar_size = computed(() => {
      // name is reactive and
      // must use .value
      switch (name.value) {
        case "xs":
          return 160;
        case "sm":
          return 160;
        case "md":
          return (42 * ((45 * ((92 * window.innerHeight) / 100)) / 100)) / 100;
        case "lg":
          return (46 * ((45 * ((92 * window.innerHeight) / 100)) / 100)) / 100;
        case "xl":
          return (45 * ((45 * ((92 * window.innerHeight) / 100)) / 100)) / 100;
        case "xxl":
          return (53 * ((45 * ((92 * window.innerHeight) / 100)) / 100)) / 100;
      }

      return undefined;
    });

    // eslint-disable-next-line vue/no-dupe-keys
    return { progress_circular_size, avatar_size, md, mdAndUp, xsAndUp };
  },
};
</script>

<style scoped>
.legend {
  border: 1px solid black !important;
}

.legend_text {
  font-size: 11px;
}
</style>
