<script setup>
import { defineProps, computed } from "vue";

import { useStore } from "vuex";

import DictionaryForReport from "@/globals/DictionaryForReport";
import CandidateReportKPILegend from "@/components/DashboardComponents/ProfilesEvaluation/CandidateReport/CandidateReportKPILegend.vue";
import CandidateReportCultureProfileChart from "@/components/DashboardComponents/ProfilesEvaluation/CandidateReport/CandidateReportCultureProfileChart.vue";
const store = useStore();

let dCandidateOrgFitColor = computed(() => {
  return calculateFitKPIColor(props.reportData.fitCandDOrg);
});

let dCandidateOrgFitBgColor = computed(() => {
  return calculateFitKPIBackgroundColor(props.reportData.fitCandDOrg);
});

function calculateFitKPIColor(value) {
  if (value < 70) return "#F7E66B";
  if (value > 80) return "#8ABE66";
  if (value >= 70 && value <= 80) return "#CADFB8";

  return "#F7E66B";
}

function calculateFitKPIBackgroundColor(value) {
  if (value < 70) return "#FBF3BB";
  if (value > 80) return "#C9E1B9";
  if (value >= 70 && value <= 80) return "#E7F0DE";

  return "#FBF3BB";
}

function getTableClass(value) {
  if (value < 70) return "low";
  if (value > 80) return "high";
  if (value >= 70 && value <= 80) return "medium";

  return "low";
}

const props = defineProps({
  reportData: Object,
  chartColors: Array,
});

const footerText = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][3];
});
const ThisRepre = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][29];
});
const EngageTitle = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][27];
});
const EngageDesired = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][31];
});
const KpiDesired = computed(() => {
  const currentLanguage = store.getters.currentLanguage;
  return DictionaryForReport[currentLanguage][32];
});
</script>

<template>
  <div id="appendix1Page1" class="page">
    <div class="page-body">
      <div class="main-container">
        <div class="title">
          {{ EngageTitle }}<strong>{{ reportData.name }}</strong> &
          <strong>{{ reportData.project }}</strong>
        </div>
        <div class="subtitle" v-html="EngageDesired"></div>
        <p class="small-dark-green-field">
          {{ ThisRepre }}
        </p>

        <div class="chart">
          <CandidateReportCultureProfileChart
            width="260px"
            height="260px"
            v-bind:series="reportData.series_6_DeseadaVsPersona"
            v-bind:colors="props.chartColors"
          />
        </div>

        <div class="title-s center" v-html="KpiDesired"></div>
        <div class="kpi center">
          <v-progress-circular
            :rotate="90"
            :size="120"
            :width="8"
            :model-value="reportData.fitCandDOrg"
            :color="dCandidateOrgFitColor"
            bg-color="transparent"
          >
            <v-avatar size="104" :color="dCandidateOrgFitBgColor">
              <div class="kpi-value-number">{{ reportData.fitCandDOrg }}</div>
            </v-avatar>
          </v-progress-circular>
        </div>
        <CandidateReportKPILegend />

        <table>
          <tr>
            <td width="12" :class="getTableClass(reportData.fitCandDOrg)"></td>
            <td class="regular-cell">{{ reportData.fitCandDOrgEval }}</td>
            <td class="regular-cell">{{ reportData.fitCandDOrgEvalText }}</td>
          </tr>
        </table>
      </div>
    </div>
    <div class="page-footer">
      {{ footerText }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
.chart {
  width: 260px;
  height: 260px;
  margin-left: auto;
  margin-right: auto;
}

.page1-lines-logo {
  display: block;
  width: 58px;
  position: absolute;
  margin-top: 380px;
  right: 0px;
  transform: rotateY(180deg);
}

table {
  td {
    border: none !important;
  }
}
</style>
