<template>
  <v-card
    elevation="0"
    :class="
      mdAndUp
        ? 'overflow-auto principles_card cardwhitePValues mt-7 '
        : 'mt-1 mb-10 text-center'
    "
    :height="principles_card_height"
    width="100%"
  >
    <v-row dense class="header-row">
      <v-col cols="3" class="d-flex align-center justify-center borderblackeEV">
        <h5 class="text-uppercase">Valores</h5>
      </v-col>
      <v-col cols="6" class="d-flex align-center justify-center borderblackeEV">
        <h5 class="text-uppercase">Comportamientos</h5>
      </v-col>
      <v-col cols="3" class="d-flex align-center justify-center borderblackeEV">
        <h5 class="text-uppercase">Probabilidad</h5>
      </v-col>
    </v-row>

    <v-row dense class="data-row" style="height: calc(100% - 50px)">
      <v-col cols="12" class="d-flex flex-column">
        <div
          v-for="item in sortedParamData"
          :key="item"
          class="d-flex justify-space-around align-center data-entry my-1"
        >
          <v-col cols="3" class="d-flex align-center justify-center">
            <p class="descriptionPredValues">{{ item.value }}</p>
          </v-col>
          <v-col cols="6" class="d-flex align-center justify-center pa-0">
            <p class="descriptionPredValues text-wrap">
              {{ item.behaviour }}
            </p>
          </v-col>
          <v-col
            cols="3"
            class="d-flex align-center justify-center pa-0 h-100"
            :style="{ backgroundColor: item.backgroundColor }"
          >
            <p class="descriptionPredValues">
              {{ item.probability }}
            </p>
          </v-col>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import { computed } from "vue";
import { useDisplay } from "vuetify";

export default {
  name: "ParamBehavior",

  computed: {
    ...mapState(["mindsetIdealFitData"]),
    sortedParamData() {
      if (!this.mindsetIdealFitData?.ParamProb) return [];

      return this.mindsetIdealFitData.ParamProb.map((prob, index) => {
        let backgroundColor;
        switch (prob) {
          case "Baja":
            backgroundColor = "#FFFC8B";
            break;
          case "Media":
            backgroundColor = "#E7F0DE";
            break;
          case "Alta":
            backgroundColor = "#B1D095";
            break;
          default:
            backgroundColor = "transparent";
            break;
        }
        return {
          value: this.mindsetIdealFitData.ParamValores?.[index] || "",
          behaviour:
            this.mindsetIdealFitData.ParamComportamientos?.[index] || "",
          probability: prob,
          backgroundColor: backgroundColor,
        };
      }).sort((a, b) => {
        if (a.probability < b.probability) return -1;
        if (a.probability > b.probability) return 1;
        return 0;
      });
    },
  },

  setup() {
    const { mdAndUp } = useDisplay();
    const { name } = useDisplay();

    const principles_card_height = computed(() => {
      const baseHeight =
        name.value === "xs" || name.value === "sm"
          ? "auto"
          : (92 * window.innerHeight) / 100;

      if (baseHeight === "auto") return baseHeight;

      switch (name.value) {
        case "md":
          return (80 * baseHeight) / 100;
        case "lg":
          return (74 * baseHeight) / 100;
        case "xl":
        case "xxl":
          return (83 * baseHeight) / 100;
        default:
          return baseHeight;
      }
    });

    return {
      principles_card_height,
      mdAndUp,
    };
  },
};
</script>

<style>
.principles_card {
  overflow-y: auto;
  background-color: #ffffff;
  border-radius: 10px;
  border: 1px solid #efefef;
  max-height: 80vh;
}

.data-entry {
  min-height: 60px;
  border-bottom: 1px solid #e0e0e0;
}

.descriptionPredValues {
  color: #636363;
  font-size: 13px;
  line-height: 1.4;
  padding: 8px;
  margin: 0;
  text-align: center;
}

.borderblackeEV {
  border-bottom: 1px solid #34495e;
  height: 50px;
}

.principles_card::-webkit-scrollbar {
  width: 10px;
}

.principles_card::-webkit-scrollbar-thumb {
  background-color: #00c3c0;
  border-radius: 20px;
  border: 2px solid #f1f2f3;
}

.principles_card::-webkit-scrollbar-track {
  border-radius: 10px;
}

@media (max-width: 768px) {
  .descriptionPredValues {
    font-size: 12px;
  }

  .data-entry {
    min-height: 50px;
  }
}

@media (max-width: 600px) {
  .descriptionPredValues {
    font-size: 11px;
  }

  .data-entry {
    min-height: 40px;
  }
}
</style>
